import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';

import AutoRefillBadge from 'components/medicine-cabinet-cart/prescription-information/auto-refill-badge/auto-refill-badge';

import {
    accountAcCodeSelector,
    accountFamilyPlansMapSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { membershipIsOnDemandSelector } from 'state/membership/membership.selector';

import { formatPrice } from 'schema/price.schema';

import { ExtendedRefillRxs } from 'types/order-prescription';

import { handleIsBirdiSelectVisible } from 'util/birdiSelect';
import { hasAdjudicatedPrice, isRxLineError } from 'util/cart';
import { safeParseFloat } from 'util/number';
import { isBirdiPlan } from 'util/plan';
import { hasPlanAutoRefillFlag } from 'util/prescription';

import { ReviewPrescriptionInformationProps } from '../review/types';
import { AutoRefillDisclaimer } from './auto-refill-disclaimer.component';

export const PrescriptionInformation: React.FC<ReviewPrescriptionInformationProps> = ({
    t,
    fullCart,
    accountHasInsurance,
    isCaregiver,
    isMembership,
    onPrescriptionAutoRefillChange
}) => {
    const cartItems = fullCart.flatMap((item) => item.extendedRefillRxs).filter((item) => !!item);
    const profileObject = useSelector(accountProfileSelector);
    const familyPlansMap: Record<string, string> = useSelector(accountFamilyPlansMapSelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);

    const planAlias = useSelector(accountAcCodeSelector);
    const isCashPricePlan = isBirdiPlan(planAlias);

    const validateDiscountVisibility = useCallback((item: ExtendedRefillRxs) => {
        if (!item) {
            return undefined;
        }

        const normalPrice = safeParseFloat(item.awpPrice);
        const birdiPrice = safeParseFloat(item.birdiPrice);

        return birdiPrice < normalPrice ? normalPrice - birdiPrice : undefined;
    }, []);

    const formatBirdiSavings = useCallback(
        (item: ExtendedRefillRxs) => {
            if (item) {
                const normalPrice = safeParseFloat(item.awpPrice);
                const birdiPrice = safeParseFloat(item.birdiPrice);

                if (birdiPrice < normalPrice) {
                    const savingsAsDollar = normalPrice - birdiPrice;
                    return t('components.medicineCabinetCart.prescriptionInfo.save', {
                        save: formatPrice(savingsAsDollar)
                    });
                }
            } else {
                return '';
            }
        },
        [t]
    );

    const getPatientName = (rxNumber: string) => {
        const cart: ExtendedRefillRxs | undefined = cartItems.find(
            (cart: ExtendedRefillRxs) => cart.rxNumber === rxNumber
        );
        return cart ? `${cart.prescriptionDetail?.patientFirstName} ${cart.prescriptionDetail?.patientLastName}` : '';
    };

    const getOrderInvoiceNumber = (rxNumber: string) => {
        const cart: ExtendedRefillRxs | undefined = cartItems.find(
            (cart: ExtendedRefillRxs) => cart.rxNumber === rxNumber
        );
        return cart?.prescriptionDetail.orderInvoiceNumber || '';
    };

    return (
        <>
            <Container className="cart-prescription" fluid>
                {cartItems?.map((item) => {
                    const currentPrescription = item.prescriptionDetail;

                    const isBirdiSelectBadgeVisible = handleIsBirdiSelectVisible({
                        item,
                        profileObject: profileObject as ProfileObjectPayload,
                        isCaregiver: isCaregiver as boolean,
                        isOnDemandPlan: isOnDemandPlan as boolean,
                        familyPlansMap: familyPlansMap
                    });

                    const planAllowsAutoRefill = profileObject
                        ? hasPlanAutoRefillFlag(currentPrescription?.epostPatientNum, profileObject)
                        : false;
                    return (
                        <Row key={item.rxNumber} className="cart-prescription--item">
                            <Col xs={12} className="mb-2">
                                {isCaregiver && (
                                    <Row>
                                        <Col>
                                            <h3 className="cart-prescription--patient-name">
                                                {getPatientName(item.rxNumber)}
                                            </h3>
                                        </Col>
                                        <Col>
                                            <h3 className="cart-prescription--reference-order">
                                                {`${t('pages.cart.referenceOrder')}: ${getOrderInvoiceNumber(
                                                    item.rxNumber
                                                )}`}
                                            </h3>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Col xs={7} sm={6} md={8} className="d-flex align-items-stretch flex-column">
                                <div className="cart-prescription--product-name">
                                    <h3 className="cart-prescription--header">
                                        {currentPrescription?.dispensedProductName}
                                    </h3>
                                    {currentPrescription.isBirdiSelect && isBirdiSelectBadgeVisible && (
                                        <PlanBadge variant="birdi-select-blue" />
                                    )}
                                </div>
                                <div className="cart-prescription--product-info">
                                    <div className="cart-prescription--product-info--rx-number">
                                        Rx #{item.rxNumber}
                                    </div>
                                    {currentPrescription?.fillQuantity && (
                                        <div className="cart-prescription--product-info--content">
                                            {t('pages.cart.quantity', {
                                                fillQuantity: currentPrescription?.fillQuantity
                                            })}
                                        </div>
                                    )}
                                    {(currentPrescription?.dispensedProductStrength ||
                                        currentPrescription?.dispensedProductUnitOfMeasure) && (
                                        <div className="cart-prescription--product-info--content">
                                            {currentPrescription?.dispensedProductStrength}
                                            {currentPrescription?.dispensedProductUnitOfMeasure}
                                        </div>
                                    )}
                                    {currentPrescription?.dispensedProductDosageForm && (
                                        <div className="cart-prescription--product-info--content dosage">
                                            {currentPrescription?.dispensedProductDosageForm}
                                        </div>
                                    )}
                                </div>
                                <div className="d-none d-md-flex align-items-baseline">
                                    {currentPrescription?.autoRefillEnabled && planAllowsAutoRefill && (
                                        <div className="mt-2">
                                            <AutoRefillBadge
                                                text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={5} sm={6} md={4} className="text-right text-lg-right">
                                {/* Pricing logic */}
                                {(hasAdjudicatedPrice(item, currentPrescription) ||
                                    isMembership ||
                                    (accountHasInsurance && !hasAdjudicatedPrice(item, currentPrescription))) &&
                                item?.hasKnownPrice ? (
                                    <>
                                        {!item.insurancePayment && validateDiscountVisibility(item) && (
                                            <div className="cart-prescription--patient-price-line">
                                                ${item.awpPrice}
                                            </div>
                                        )}
                                        <div className="cart-prescription--patient-price-total">
                                            {formatPrice(item.isUsingBirdiPrice ? item.birdiPrice : item.patientCopay)}
                                        </div>
                                        {/* Est.Copay - just for insurance patients */}
                                        {item.insurancePayment !== null && item.insurancePayment > 0 && (
                                            <div className="cart-prescription--patient-price-info">
                                                {t('components.medicineCabinetCart.prescriptionInfo.copay')}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="prescription-item-no-price">
                                        {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}
                                    </div>
                                )}

                                {((isCashPricePlan && item?.hasKnownPrice) ||
                                    (accountHasInsurance && item?.hasKnownPrice && isRxLineError(item))) && (
                                    <div>
                                        {/* Birdi price */}
                                        <div className="cart-prescription--patient-price-info">
                                            <BirdiPriceIcon className="cart-prescription--patient-price-info-icon" />
                                            <div>{t('components.medicineCabinetCart.prescriptionInfo.birdiPrice')}</div>
                                        </div>

                                        {/* Discount price */}
                                        <div className="cart-prescription--patient-price-saving">
                                            {formatBirdiSavings(item)}
                                        </div>
                                    </div>
                                )}
                            </Col>
                            <Col className="d-block d-md-none" xs={12}>
                                <div className="d-flex align-items-baseline">
                                    {currentPrescription?.autoRefillEnabled && planAllowsAutoRefill && (
                                        <div className="mt-2">
                                            <AutoRefillBadge
                                                text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>

                            {!currentPrescription?.autoRefillEnabled &&
                                currentPrescription?.autoRefillEligible &&
                                planAllowsAutoRefill && (
                                    <Col className="mt-3">
                                        <AutoRefillDisclaimer
                                            t={t}
                                            prescription={currentPrescription}
                                            onAutoRefillChange={(
                                                prescription: PrescriptionObjectPayload,
                                                value: boolean
                                            ) => onPrescriptionAutoRefillChange(prescription, value)}
                                        ></AutoRefillDisclaimer>
                                    </Col>
                                )}
                        </Row>
                    );
                })}
            </Container>
        </>
    );
};
